/* eslint-disable */
// https://dev2.bhsn.it/api/btls/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'btls';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * 표준계약서 조회 V2
 * @returns BtlsResponseStandardFormDetailDtoV2 OK
 * @throws ApiError
 */
export function getStandardFormV2(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormDetailDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/v2',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 수정
 * @returns BtlsResponseStandardFormCreatedDtoV2 OK
 * @throws ApiError
 */
export function updateStandardFormV2(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormCreatedDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/standard-forms/{standardFormId}/v2',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 조회
 * @returns BtlsResponseStandardFormDetailDto OK
 * @throws ApiError
 */
export function getStandardForm(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 수정
 * @returns BtlsResponseStandardFormCreatedDto OK
 * @throws ApiError
 */
export function updateStandardForm(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/standard-forms/{standardFormId}/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteStandardForm(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/standard-forms/{standardFormId}/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 버전 변경
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function rollbackVersion(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormTemplateRollbackForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/standard-forms/{standardFormId}/templates/rollback/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 활성화 상태 변경
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function changeActiveStatus(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormActiveStatusForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/standard-forms/{standardFormId}/active-status/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 조회
 * @returns BtlsResponseListStandardFormTemplateDto OK
 * @throws ApiError
 */
export function listStandardFormTemplates(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseListStandardFormTemplateDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/templates/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 양식 업로드
 * @returns BtlsResponseStandardFormTemplateCreatedDto OK
 * @throws ApiError
 */
export function uploadStandardFormTemplate(
    {
        standardFormId,
        formData,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        formData?: {
            form: BtlsStandardFormTemplateCreateForm;
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormTemplateCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standard-forms/{standardFormId}/templates/v1',
                path: {
                    standardFormId: standardFormId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 다운로드 by JSON
 * @returns BtlsStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadByJson(
    {
        standardFormId,
        fileFormat,
        saveMode,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 다운로드 포맷
         */
        fileFormat: 'DOCX' | 'PDF';
        /**
         * 다운로드 형태
         */
        saveMode: 'CLEANED' | 'ALL';
        requestBody: BtlsDocument;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsStreamingResponseBody> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standard-forms/{standardFormId}/templates/download/by-json/v1',
                path: {
                    standardFormId: standardFormId,
                },
                query: {
                    fileFormat: fileFormat,
                    saveMode: saveMode,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 북마크 등록
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function registerBookmark(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standard-forms/{standardFormId}/bookmarks/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 북마크 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteBookmark(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/standard-forms/{standardFormId}/bookmarks/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 첨부파일 등록
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function createStandardFormAttachment(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormAttachmentCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standard-forms/{standardFormId}/attachments/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 목록 조회 V2
 * @returns BtlsResponsePageStandardFormDtoV2 OK
 * @throws ApiError
 */
export function pageByConditionV2(
    {
        searchKeyword,
        contractTypes,
        activeStatuses,
        languages,
        userIds,
        searchKeywordType,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 계약서 상태
         */
        activeStatuses?: 'ACTIVE' | 'INACTIVE';
        /**
         * 언어
         */
        languages?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 검색 키워드 타입
         */
        searchKeywordType?: 'ALL' | 'NAME' | 'DESCRIPTION' | 'GUIDELINES' | 'USE_CASES' | 'OUR_POSITION';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponsePageStandardFormDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/v2',
                query: {
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    activeStatuses: activeStatuses,
                    languages: languages,
                    userIds: userIds,
                    searchKeywordType: searchKeywordType,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 생성
 * @returns BtlsResponseStandardFormCreatedDtoV2 OK
 * @throws ApiError
 */
export function createStandardFormV2(
    {
        formData,
    }: {
        formData?: {
            form: BtlsStandardFormCreateForm;
            standardFormFile: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormCreatedDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standard-forms/v2',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 목록 조회
 * @returns BtlsResponsePageStandardFormDto OK
 * @throws ApiError
 */
export function pageByCondition(
    {
        searchKeyword,
        contractTypes,
        activeStatuses,
        languages,
        userIds,
        searchKeywordType,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 계약서 상태
         */
        activeStatuses?: 'ACTIVE' | 'INACTIVE';
        /**
         * 언어
         */
        languages?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 검색 키워드 타입
         */
        searchKeywordType?: 'ALL' | 'NAME' | 'DESCRIPTION' | 'GUIDELINES' | 'USE_CASES' | 'OUR_POSITION';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponsePageStandardFormDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/v1',
                query: {
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    activeStatuses: activeStatuses,
                    languages: languages,
                    userIds: userIds,
                    searchKeywordType: searchKeywordType,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 생성
 * @returns BtlsResponseStandardFormCreatedDto OK
 * @throws ApiError
 */
export function createStandardForm(
    {
        formData,
    }: {
        formData?: {
            form: BtlsStandardFormCreateForm;
            standardFormFile: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standard-forms/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 버전 별 조회(document)
 * @returns BtlsResponseStandardFormTemplateDetailDto OK
 * @throws ApiError
 */
export function getTemplateByVersion(
    {
        standardFormId,
        version,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormTemplateDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/templates/version/{version}/v1',
                path: {
                    standardFormId: standardFormId,
                    version: version,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 조회(document)
 * @returns BtlsResponseStandardFormTemplateDetailDto OK
 * @throws ApiError
 */
export function getTemplate(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormTemplateDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/templates/latest/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 클린버전 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadCleaned(
    {
        standardFormId,
        version,
        format,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
        /**
         * 다운로드 포맷
         */
        format: 'DOCX' | 'PDF';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/templates/download/CLEANED/v1',
                path: {
                    standardFormId: standardFormId,
                },
                query: {
                    version: version,
                    format: format,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 첨부파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadStandardFormAttachment(
    {
        standardFormId,
        standardFormAttachmentId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 표준계약서 첨부파일 ID
         */
        standardFormAttachmentId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/{standardFormId}/attachments/{standardFormAttachmentId}/download/v1',
                path: {
                    standardFormId: standardFormId,
                    standardFormAttachmentId: standardFormAttachmentId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 구성에 필요한 정보 목록 조회 V2
 * @returns BtlsResponseListStandardFormTemplateConfigDtoV2 OK
 * @throws ApiError
 */
export function getTemplateConfigsV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<BtlsResponseListStandardFormTemplateConfigDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/template/configs/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 구성에 필요한 정보 목록 조회
 * @returns BtlsResponseListStandardFormTemplateConfigDto OK
 * @throws ApiError
 */
export function getTemplateConfigs(_?: any, requestConfig?: RequestConfig): AxiosPromise<BtlsResponseListStandardFormTemplateConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/template/configs/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 북마크 조회 V2
 * @returns BtlsResponsePageStandardFormDtoV2 OK
 * @throws ApiError
 */
export function pageByConditionV21(
    {
        page,
        size = 30,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponsePageStandardFormDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/bookmarks/v2',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 북마크 조회
 * @returns BtlsResponsePageStandardFormDto OK
 * @throws ApiError
 */
export function pageByCondition1(
    {
        page,
        size = 30,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponsePageStandardFormDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standard-forms/bookmarks/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 첨부파일 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteStandardFormAttachment(
    {
        standardFormId,
        standardFormAttachmentId,
    }: {
        standardFormId: number;
        standardFormAttachmentId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/standard-forms/{standardFormId}/attachments/{standardFormAttachmentId}/v1',
                path: {
                    standardFormId: standardFormId,
                    standardFormAttachmentId: standardFormAttachmentId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 조회 V2
 * @returns BtlsResponseGlobalStandardFormDetailDtoV2 OK
 * @throws ApiError
 */
export function getGlobalStandardFormV2(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseGlobalStandardFormDetailDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/v2',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 수정 V2
 * @returns BtlsResponseGlobalStandardFormCreatedDtoV2 OK
 * @throws ApiError
 */
export function updateGlobalStandardFormV2(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsGlobalStandardFormUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseGlobalStandardFormCreatedDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/standard-forms/{standardFormId}/v2',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 조회
 * @returns BtlsResponseGlobalStandardFormDetailDto OK
 * @throws ApiError
 */
export function getGlobalStandardForm(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseGlobalStandardFormDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 수정
 * @returns BtlsResponseGlobalStandardFormCreatedDto OK
 * @throws ApiError
 */
export function updateGlobalStandardForm(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsGlobalStandardFormUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseGlobalStandardFormCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/standard-forms/{standardFormId}/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteGlobalStandardForm(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/standard-forms/{standardFormId}/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 버전 변경
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function rollbackGlobalVersion(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormTemplateRollbackForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/standard-forms/{standardFormId}/templates/rollback/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 활성화 상태 변경
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function changeGlobalActiveStatus(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormActiveStatusForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/standard-forms/{standardFormId}/active-status/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 노출 글로벌 표준계약서 조회
 * @returns BtlsResponseListGlobalStandardFormDto OK
 * @throws ApiError
 */
export function getWorkspaceGlobalStandardForms(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseListGlobalStandardFormDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/workspace/{workspaceId}/v1',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 노출 글로벌 표준계약서 목록 수정
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function updateWorkspaceGlobalStandardForms(
    {
        workspaceId,
        requestBody,
    }: {
        workspaceId: number;
        requestBody: BtlsWorkspaceGlobalStandardFormUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/standard-forms/workspace/{workspaceId}/v1',
                path: {
                    workspaceId: workspaceId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 조회
 * @returns BtlsResponseListStandardFormTemplateDto OK
 * @throws ApiError
 */
export function listGlobalStandardFormTemplates(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseListStandardFormTemplateDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/templates/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 양식 업로드
 * @returns BtlsResponseStandardFormTemplateCreatedDto OK
 * @throws ApiError
 */
export function uploadGlobalStandardFormTemplate(
    {
        standardFormId,
        formData,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        formData?: {
            form: BtlsStandardFormTemplateCreateForm;
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormTemplateCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/standard-forms/{standardFormId}/templates/v1',
                path: {
                    standardFormId: standardFormId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 첨부파일 등록
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function createGlobalStandardFormAttachment(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: BtlsStandardFormAttachmentCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/standard-forms/{standardFormId}/attachments/v1',
                path: {
                    standardFormId: standardFormId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 목록 조회 V2
 * @returns BtlsResponsePageGlobalStandardFormDtoV2 OK
 * @throws ApiError
 */
export function pageGlobalByConditionV2(
    {
        searchKeyword,
        contractTypes,
        activeStatuses,
        languages,
        userIds,
        searchKeywordType,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 계약서 상태
         */
        activeStatuses?: 'ACTIVE' | 'INACTIVE';
        /**
         * 언어
         */
        languages?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 검색 키워드 타입
         */
        searchKeywordType?: 'ALL' | 'NAME' | 'DESCRIPTION' | 'GUIDELINES' | 'USE_CASES' | 'OUR_POSITION';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponsePageGlobalStandardFormDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/v2',
                query: {
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    activeStatuses: activeStatuses,
                    languages: languages,
                    userIds: userIds,
                    searchKeywordType: searchKeywordType,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 생성 V2
 * @returns BtlsResponseGlobalStandardFormCreatedDtoV2 OK
 * @throws ApiError
 */
export function createGlobalStandardFormV2(
    {
        formData,
    }: {
        formData?: {
            form: BtlsGlobalStandardFormCreateForm;
            standardFormFile: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseGlobalStandardFormCreatedDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/standard-forms/v2',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 목록 조회
 * @returns BtlsResponsePageGlobalStandardFormDto OK
 * @throws ApiError
 */
export function pageGlobalByCondition(
    {
        searchKeyword,
        contractTypes,
        activeStatuses,
        languages,
        userIds,
        searchKeywordType,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 계약서 상태
         */
        activeStatuses?: 'ACTIVE' | 'INACTIVE';
        /**
         * 언어
         */
        languages?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 검색 키워드 타입
         */
        searchKeywordType?: 'ALL' | 'NAME' | 'DESCRIPTION' | 'GUIDELINES' | 'USE_CASES' | 'OUR_POSITION';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponsePageGlobalStandardFormDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/v1',
                query: {
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    activeStatuses: activeStatuses,
                    languages: languages,
                    userIds: userIds,
                    searchKeywordType: searchKeywordType,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 생성
 * @returns BtlsResponseGlobalStandardFormCreatedDto OK
 * @throws ApiError
 */
export function createGlobalStandardForm(
    {
        formData,
    }: {
        formData?: {
            form: BtlsGlobalStandardFormCreateForm;
            standardFormFile: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseGlobalStandardFormCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/standard-forms/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 버전 별 조회(document)
 * @returns BtlsResponseStandardFormTemplateDetailDto OK
 * @throws ApiError
 */
export function getGlobalTemplateByVersion(
    {
        standardFormId,
        version,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormTemplateDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/templates/version/{version}/v1',
                path: {
                    standardFormId: standardFormId,
                    version: version,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 조회(document)
 * @returns BtlsResponseStandardFormTemplateDetailDto OK
 * @throws ApiError
 */
export function getGlobalTemplate(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseStandardFormTemplateDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/templates/latest/v1',
                path: {
                    standardFormId: standardFormId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 클린버전 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadGlobalCleaned(
    {
        standardFormId,
        version,
        format,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
        /**
         * 다운로드 포맷
         */
        format: 'DOCX' | 'PDF';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/templates/download/CLEANED/v1',
                path: {
                    standardFormId: standardFormId,
                },
                query: {
                    version: version,
                    format: format,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 첨부파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadGlobalStandardFormAttachment(
    {
        standardFormId,
        standardFormAttachmentId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 표준계약서 첨부파일 ID
         */
        standardFormAttachmentId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/{standardFormId}/attachments/{standardFormAttachmentId}/download/v1',
                path: {
                    standardFormId: standardFormId,
                    standardFormAttachmentId: standardFormAttachmentId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 노출 글로벌 표준계약서 조회 V2
 * @returns BtlsResponseListGlobalStandardFormDtoV2 OK
 * @throws ApiError
 */
export function getWorkspaceGlobalStandardFormsV2(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponseListGlobalStandardFormDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/workspace/{workspaceId}/v2',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 구성에 필요한 정보 목록 조회 V2
 * @returns BtlsResponseListStandardFormTemplateConfigDtoV2 OK
 * @throws ApiError
 */
export function getGlobalTemplateConfigsV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<BtlsResponseListStandardFormTemplateConfigDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/template/configs/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 템플릿 구성에 필요한 정보 목록 조회
 * @returns BtlsResponseListStandardFormTemplateConfigDto OK
 * @throws ApiError
 */
export function getGlobalTemplateConfigs(_?: any, requestConfig?: RequestConfig): AxiosPromise<BtlsResponseListStandardFormTemplateConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/standard-forms/template/configs/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 표준계약서 첨부파일 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteGlobalStandardFormAttachment(
    {
        standardFormId,
        standardFormAttachmentId,
    }: {
        standardFormId: number;
        standardFormAttachmentId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<BtlsResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/standard-forms/{standardFormId}/attachments/{standardFormAttachmentId}/v1',
                path: {
                    standardFormId: standardFormId,
                    standardFormAttachmentId: standardFormAttachmentId,
                },
            }),
            requestConfig,
        ),
    );
}
